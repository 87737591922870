<template>
  <div :class="getClass()" class="z-index--1">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tile',
  props: ['z'],
  data: () => {
    return {

    }
  },
  methods: {
    click: function() {
      console.log('click');
    },

    getClass: function() {
      let classList = ["Tile"];
      if(this.z === '1') {
        classList.push('elevation--1');
      }

      classList.push('corners--rounded');
      return classList.join(" ");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.Tile {
  max-width: 600px;
  margin: 0 auto 15px auto;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  .corners--rounded();

  h2 {
    // Conflicts with putting all the font colors in app.vue. What do?
    color: white;
    margin: 15px;
  }

  h3 {
    color: white;
  }

  p {
    color: white;
  }


  &:first-of-type {
    
  }

  &.elevation {
    &--1 {
      background: @background-color-secondary;
    }
  }
  
  & > * {
    opacity: 1;
  }
}
</style>
