<template>
  <Tile z="1">
    <div class="new">
      <div v-if="!$store.state.game.game_obj.publicId">
        No game id!
      </div>
      <div v-if="!$store.state.game.game_obj.settings.acceptingPlayers && $store.state.game.game_obj.hasStarted">
        <h2>Game already started!</h2>
        <h3>Would you like to observe?</h3>
      </div>
      <div v-if="($store.state.game.game_obj.publicId && !$store.state.game.game_obj.hasStarted) || (true)">
        <h2>Player Info</h2>

        <p class="font-color--primary">
          Build your player profile. You can always update this later.
        </p>

        <ProfileCreator
          ref="creator"
          @validity="isValid=$event"
          v-bind:name="defaultPlayerSettings.name"
          v-bind:avatar="defaultPlayerSettings.avatar">
        </ProfileCreator>
        <Button :enabled="isValid" :callback="setPlayerInfo" :processing="isSending">{{$store.state.game.game_obj.hostId ? 'Join Game' : 'Create Game'}}</Button>
      </div>
    </div>
  </Tile>
</template>

<script>
  import ProfileCreator from '@/views/partials/ProfileCreator.vue';
  import Button from '@/components/Button.vue';
  import Tile from '@/components/Tile.vue';
  import router from '@/router/index.js';

  export default {
    name: 'New',
    components: {
      ProfileCreator,
      Tile,
      Button
    },
    data: () => {

      return {
        isSending: false,
        isValid: false,
      }
    },
    computed: {
      defaultPlayerSettings: () => {
        const name = localStorage.getItem('default--player_name');
        const avatar = localStorage.getItem('default--player_avatar');

        return {name, avatar};
      }
    },

    methods: {

      async setPlayerInfo() {
        this.isSending = true;

        const data = this.$refs.creator;

        // TODO - Update to use the more usable "set_plyaer"
        this.$store.commit('set_name', data.playerName);
        this.$store.commit('set_avatar', data.playerAvatar);

        this.$store.commit('set_player_defaults', this.$refs.creator.getFinalBody());


        let response = await this.$store.dispatch('join_game', data.getFinalBody());

        // TODO - If failure, show an error message
        if(response === 'Success') {
          console.log(response);
          router.push(`/game/${this.$store.state.game.game_obj.publicId}/lobby`)
        }
      }
    }
  }
</script>

<style scoped lang="less">



</style>
