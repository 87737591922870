<template>
  <div :class="'ProfilePic z-index--1 size--'+size">
  <img src="@/../assets/profile/garruk.jpg" v-if="icon==='garruk'"/>
  <img src="@/../assets/profile/ajani.jpg" v-if="icon==='ajani'"/>
  <img src="@/../assets/profile/jace.jpg" v-if="icon==='jace'"/>
  <img src="@/../assets/profile/chandra.jpg" v-if="icon==='chandra'"/>
  <img src="@/../assets/profile/liliana.jpg" v-if="icon==='liliana'"/>
</div>
</template>

<script>
export default {
  name: 'ProfilePic',
  props: {
    icon: {},
    size: {
      default: "normal"
    },
  },
  data: () => {
    return {

    }
  },
  computed: {
    iconUrl: function() {
      let icon = this.icon;

      if(!icon) {
        icon = 'rootwalla';
      }
      return `/img/profile/${this.icon}.jpg`;
    }
  },
  methods: {
    click: function() {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.ProfilePic {
  
  position: relative;

  &.size--extra-large {
    max-height: 100px;
    border-radius: 50%;
    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }
  }

  
  
  &.size--large {
    max-height: 75px;
    border-radius: 50%;
    img {
      height: 75px;
      width: 75px;
      border-radius: 50%;
    }
  }

  &.size--normal {
    max-height: 50px;
    border-radius: 25px;
    img {
      height: 50px;
      width: 50px;
      border-radius: 25px;
    }
  }

  &.size--small {
    max-height: 24px;
    border-radius: 12px;
    img {
      height: 24px;
      width: 24px;
      border-radius: 12px;
    }
  }

  &.size--tiny {
    max-height: 16px;
    border-radius: 8px;
    img {
      height: 16px;
      width: 16px;
      border-radius: 8px;
    }
  }
}
</style>
